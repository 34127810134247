import React, { FunctionComponent } from 'react'
import { IconButton } from '@wh-components/core/Button/Button'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import ArrowLeft from '@wh-components/icons/ArrowLeft'
import ArrowRight from '@wh-components/icons/ArrowRight'
import styled, { css } from 'styled-components'

export const ArrowIconButton: FunctionComponent<BoxProps & { onClick: () => void; direction: 'left' | 'right'; tabbable: boolean }> = ({
    onClick,
    direction,
    tabbable = true,
    ...props
}) => (
    <Box
        display={{ phone: 'none', tablet: 'block' }}
        position="absolute"
        top="calc(50% - 20px)" /* Button size is 40x40 */
        zIndex={1000}
        left={direction === 'left' ? 0 : 'auto'}
        right={direction === 'right' ? 0 : 'auto'}
        {...props}
        css={css`
            opacity: 0;
            transition: opacity 0.2s ease-in-out;

            ${HoverContainer}:hover & {
                opacity: 0.8;
            }
        `}
    >
        <IconButton
            onClick={onClick}
            Icon={direction === 'left' ? ArrowLeft : ArrowRight}
            variant="transparent"
            color="complimentary"
            aria-label={direction === 'left' ? 'Vorheriges Bild' : 'Nächstes Bild'}
            css={css`
                color: ${(p) => p.theme.colors.semantic.foreground.white};
            `}
            tabIndex={tabbable ? 0 : -1}
        />
    </Box>
)

export const HoverContainer = styled(Box)``
